<!-- #Developer by Halil Kılıçarslan -->

<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :items="teams"
    :loading="loading"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
  >
    <template v-slot:prepend-item v-if="$attrs.hasOwnProperty('multiple') && teams.length">
      <v-list-item ripple @click="selectAll">
        <v-list-item-action>
          <v-icon :color="Array.isArray(selected) && selected.length > 0 ? 'primary' : ''">{{ _icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="body-2">{{ $t("buttons.select_all") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
    <template v-if="$attrs.hasOwnProperty('multiple')" v-slot:selection="{ item, index }">
      <div v-if="index === 0">
        {{ index === 0 ? `${selected.length} ${$t("global.selected", [$t("global.team")])}` : "" }}
      </div>
    </template>
    <template v-else v-slot:selection="{ item, index }">
      <span class="text-truncate"> {{ item.name }}</span>
    </template>
    <template slot="item" slot-scope="data">
      <template>
        <v-list-item-avatar size="30" :color="data.item.color">
          <span class="white--text font-weight-bold">{{ data.item.name.substring(0, 2).toUpperCase() }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.name"></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
  import * as GQL from "./gql";

  export default {
    inheritAttrs: false,
    name: "TeamPicker",
    data: vm => ({
      loading: false,
      teams: [],
      selected: []
    }),
    props: {
      value: [String, Object, Array]
    },
    watch: {
      value: {
        handler(v) {
          if (v) {
            this.selected = v;
          } else {
            this.selected = null;
          }
        },
        immediate: true
      }
    },
    computed: {
      isAllSelectedEmployees() {
        return this.selected.length === this.teams.length;
      },
      isLikesSelectedEmployees() {
        return this.selected.length > 0 && !this.isAllSelectedEmployees;
      },
      _icon() {
        if (this.isAllSelectedEmployees) return "mdi-close-box";
        if (this.isLikesSelectedEmployees) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      }
    },
    methods: {
      async fetchTeams() {
        this.teams = [];
        this.loading = true;
        await this.$apollo
          .query({
            query: GQL.TEAMS,
            fetchPolicy: "no-cache",
            errorPolicy: "all"
          })
          .then(({ data: { teams }, errors }) => {
            if (!errors) this.teams = teams;
          })
          .catch(e => {
            console.log("e", e);
          })
          .then(() => {
            this.loading = false;
          });
      },
      selectAll() {
        this.$nextTick(() => {
          if (this.isAllSelectedEmployees) {
            this.selected = [];
          } else {
            this.selected = this.teams.map(item => item.id);
          }
          this.$emit("input", this.selected);
        });
      }
    },

    created() {
      if (this.$attrs.hasOwnProperty("multiple")) {
        this.selected = [];
      } else {
        this.selected = this.value;
      }
      this.fetchTeams();
    }
  };
</script>

<style scoped></style>
